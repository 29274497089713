import classNames from 'classnames'
import React from 'react'
import Image from 'next/image'
import {TilesStoryblok} from 'types/storyblok-types'
import {storyblokEditable} from '@storyblok/react'
import linkTypeChecker from 'util/linkTypeChecker'
import Link from 'next/link'

export type Tile = {
  uid: string
  title: string
  subtitle?: string
  image_url: string
  image_alt?: string
  cta_url?: string
  cta_url_target?: string
}

type TilesProps = {
  tiles: Tile[]
  number_of_columns: string
}

export const TilesBlock = ({tiles, number_of_columns}: TilesProps) => {
  const gridClassNames = classNames('grid gap-10 grid-cols-1', {
    'lg:grid-cols-2': number_of_columns === '2',
    'lg:grid-cols-3': number_of_columns === '3',
  })

  const tileClassNames = classNames('inset-0 w-full', {
    'h-[214px] lg:h-[340px]': number_of_columns === '2',
    'h-[214px]': number_of_columns === '3',
  })

  const subtitleClass = classNames(
    'font-body text-sm md:text-base font-semibold text-white',
    {
      'md:text-base': number_of_columns === '3',
      'md:text-2xl': number_of_columns !== '3',
    },
  )

  return (
    <div className={gridClassNames}>
      {tiles.map(tile => (
        <div key={tile.uid} className="relative rounded-2xl">
          <div className={tileClassNames}>
            <Image
              src={tile.image_url}
              alt={tile.image_alt ?? ''}
              fill={true}
              className="gap rounded-2xl object-cover"
            />
            <div className="relative size-full rounded-2xl bg-gradient-to-r from-black via-black via-10% to-transparent"></div>
          </div>

          <Link
            href={tile.cta_url ?? '#'}
            target={tile.cta_url_target}
            className={`absolute flex h-[214px] flex-col items-start justify-between overflow-hidden p-4 no-underline ${tileClassNames}`}
          >
            <div className="w-1/2 font-rubik text-2xl font-bold text-white md:text-[32px]">
              {tile.title}
            </div>

            <div className="flex w-full items-center justify-between">
              <div className={subtitleClass}>{tile.subtitle}</div>
              <div className="flex size-[34px] items-center justify-center rounded-lg bg-black/20">
                <svg
                  width="9"
                  height="14"
                  viewBox="0 0 9 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.79093 7.67498C8.17385 7.29516 8.17385 6.67834 7.79093 6.29852L1.90927 0.464531C1.52635 0.0847129 0.904485 0.084713 0.521565 0.464531C0.138644 0.844348 0.138644 1.46117 0.521565 1.84099L5.7109 6.98827L0.524628 12.1356C0.141708 12.5154 0.141708 13.1322 0.524628 13.512C0.907549 13.8918 1.52941 13.8918 1.91233 13.512L7.79399 7.67802L7.79093 7.67498Z"
                    fill="#F0FCFF"
                  />
                </svg>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  )
}

type TilesContainerProps = {
  blok: TilesStoryblok
}
const TilesContainer = ({blok}: TilesContainerProps) => {
  const tiles: Tile[] = blok.items.flatMap(item => {
    return {
      uid: item._uid,
      title: item.title,
      subtitle: item.subtitle,
      image_url: item.image.filename,
      image_alt: item.image.alt,
      cta_url: linkTypeChecker(item.cta_link),
      cta_url_target: item.cta_link.target,
    }
  })

  return (
    <div {...storyblokEditable(blok)}>
      <TilesBlock tiles={tiles} number_of_columns={blok.number_of_columns} />
    </div>
  )
}
export default TilesContainer
